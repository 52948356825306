import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const Book = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "71cVD5fgy7L.jpg" }) {
        desktop: childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
        mobile: childImageSharp {
          fixed(width: 130) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const linkStyle = {
    fontSize: '1.2rem',
    fontWeight: '400',
    display: 'block',
    marginBottom: '1rem',
    boxSizing: 'border-box',
  };

  const imgStyle = {
    border: 'solid 1px black',
  };

  return (
    <Layout location={location}>
      <Seo title='Book' />

      <div style={{ display: 'flex' }}>
        <div
          className='book__image-wrapper'
          style={{
            width: '1400px',
            margin: '0 auto',
            marginBottom: `1.45rem`,
            marginRight: '2rem',
            boxShadow: '0px 0px 6px #fff',
          }}
        >
          <Img imgStyle={imgStyle} fluid={data.file.desktop.fluid} />
        </div>

        <div>
          <p style={{ fontSize: '1.2rem' }}>
            Ten years ago, I began going through old letters and journals and
            compiling stories of our life as missionaries. After collecting over
            one hundred and fifty stories, I decided to write them down for
            posterity. The result is this book, "God My Rock".
          </p>
          <div>
            <div style={{ display: 'flex' }} className='mobile-only'>
              <div
                className='book__image-wrapper-mobile'
                style={{
                  margin: '0 auto',
                  marginBottom: `1.45rem`,
                  marginRight: '1rem',
                }}
              >
                <Img imgStyle={imgStyle} fixed={data.file.mobile.fixed} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <a
                  style={linkStyle}
                  className='my-button'
                  href='https://www.westbowpress.com/en/bookstore/bookdetails/800631-god-my-rock'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Paperback ⟶
                </a>
                <a
                  style={linkStyle}
                  className='my-button'
                  href='https://go2rpi.com/god-my-rock-faith-adventures-of-a-missionary-mom-hardback-book/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Hardcover ⟶
                </a>
                <a
                  style={linkStyle}
                  className='my-button'
                  href='https://go2rpi.com/god-my-rock'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Discounted multiple book orders ⟶
                </a>
                <footer
                  style={{
                    fontSize: '0.9rem',
                    fontStyle: 'italic',
                    fontWeight: 'bold',
                    marginTop: 0,
                    textAlign: 'left',
                  }}
                >
                  Also available in: Kindle, Nook, Ebook, Google play books and
                  Apple book formats.
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Book;
